.background {
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-image: url("./../../resources/images/background2.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;

  .container {
    .heading {
      padding-left: 50px;
      padding-bottom: 20px;
      h3 {
        font-weight: 400;
        font-size: 60px;
      }
      p {
        font-size: 35px;
        font-style: italic;
      }
    }
    .posts {
      background-color: rgba(250, 249, 247, 0.4);
      padding-inline: 30px !important;
      padding-bottom: 80px;

      .card {
        margin-bottom: 20px;
        margin-left: 30px;
        border: 1px solid #cfd3d4;
        padding-bottom: 20px;

        .avatar {
          margin-right: -150px;
          .image {
            padding-top: 30px;
            font-size: 20px;
            width: 50%;
          }
        }
        .title {
          font-size: 30px;
          align-content: left;
        }
        .buttons {
          size: "large";
        }
        .description {
          font-size: 20px;
        }
      }
    }
  }

  .mobile {
    .heading {
      padding-inline: 40px;
      padding-bottom: 20px;
      h3 {
        font-weight: 400;
        font-size: 30px;
        padding-top: 10px;
      }
      p {
        font-weight: 400;
        font-size: 20px;
        font-style: italic;
        padding-bottom: 10px;
      }
    }
    .posts {
      background-color: rgba(250, 249, 247, 0.4);
      padding-inline: 30px !important;
      padding-bottom: 80px;

      .card {
        margin-bottom: 20px;
        margin-left: 30px;
        border: 1px solid #cfd3d4;
        padding-bottom: 20px;

        .avatar {
          margin-right: -150px;
          .image {
            padding-top: 30px;
            font-size: 20px;
            width: 50%;
          }
        }
        .title {
          font-size: 30px;
          align-content: left;
        }
        .buttons {
          size: "large";
        }
        .description {
          font-size: 20px;
        }
      }
    }
  }
}
