.layout {
  min-height: 100vh;
  width: 100%;

  .header {
    background: transparent;
    height: 60px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .logo {
      padding-top: 1px;
      line-height: 110px;
    }
    .options {
      padding-top: 20px;
      .item {
        font-size: 25px;
        font-weight: 600;
        color: rgb(39, 39, 39);
      }
    }
  }
  .mobileHeader {
    background: transparent;
    height: 60px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .logo {
      padding-top: 25px;
      line-height: 45px;
      font-size: 10px;
      height: 7%;
      width: 7%;
    }
    .options {
      padding-top: 20px;
      .item {
        font-size: 20px;
        font-weight: 600;
        color: rgb(39, 39, 39);
      }
    }
  }

  .content {
    flex: 1;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    //color: rgba(139, 141, 151, 0.5);

    .copyright {
      display: flex;
      justify-content: center;
      margin-top: 8px;

      a {
        color: rgba(139, 141, 151, 0.5);
      }
    }
  }

  .mobileFooter {
    background: transparent;
    .links {
      display: flex;
      justify-content: center;
    }
    .copyright {
      display: flex;
      justify-content: center;
      margin-top: 8px;

      a {
        color: rgba(139, 141, 151, 0.5);
      }
    }
  }
}
