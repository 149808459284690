.container {
  .section1 {
    padding: 50px;
    .text {
      h2 {
        font-weight: 550;
        font-size: 60px;
        padding-top: 40px;
      }
      p {
        font-size: 35px;
      }
    }
    .mainImage {
      width: 300%;
      padding-left: 126px;
    }
  }
  .section2 {
    padding-right: 50px;
    padding-left: 50px;
    .text {
      h3 {
        font-weight: 400;
        font-size: 60px;
        padding-top: 20px;
      }
    }
    .card {
      height: 90%;
      background-color: rgba(250, 249, 247, 0.5);
      vertical-align: middle;
      padding-bottom: 100px;
      .title {
        display: flex;
        align-items: center;
        .icon {
          font-size: 40px;
          padding-bottom: 20px;
        }
        .text {
          padding-left: 30px;
          font-size: 35px;
          padding-bottom: 20px;
        }
      }
      .buttons {
        size: "large";
      }
      .description {
        font-size: 20px;
      }
    }
  }

  .section3 {
    background-image: url("./../../resources/images/background2.svg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding-bottom: 70px;

    .text {
      h3 {
        padding-left: 50px;
        font-weight: 400;
        font-size: 60px;
        padding-top: 25px;
        text-align: center;
      }
    }
    .carrousel {
      .slide {
        margin: 0;
        height: 150px;
        line-height: 150px;
        text-align: center;
        font-weight: 600;
        font-size: 60px;
      }
    }
  }

  .section4 {
    padding-bottom: 80px;
    text-align: center;

    .text {
      h3 {
        font-weight: 400;
        font-size: 50px;
        padding-top: 10px;
      }
      p {
        font-weight: 400;
        font-size: 30px;
      }
    }
    .buttons {
      width: 18%;
      height: 20%;
      margin-top: 48px;

      font-weight: 400;
      font-size: 30px;
      .icon {
        padding-right: 10px;
      }
    }
  }
}

.mobile {
  .section1 {
    padding: 0px;
    display: flex;
    justify-content: center;
    .text {
      padding: 40px;
      h2 {
        font-weight: 550;
        font-size: 29px;
        padding-top: 20px;
      }
      p {
        font-size: 20px;
      }
    }
    .mainImage {
      width: 150%;
      padding-left: 70px;
    }
  }
  .section2 {
    padding-right: 50px;
    padding-left: 50px;
    .text {
      h3 {
        font-weight: 400;
        font-size: 30px;
      }
    }
    .card {
      height: 90%;
      background-color: rgba(250, 249, 247, 0.5);
      vertical-align: middle;
      padding-bottom: 40px;
      .title {
        display: flex;
        align-items: center;
        .icon {
          font-size: 25px;
          padding-bottom: 20px;
        }
        .text {
          padding-left: 15px;
          font-size: 22px;
        }
      }
      .buttons {
        font-size: 10px;
      }
      .description {
        font-size: 16px;
        padding-bottom: 40px;
      }
    }
  }

  .section3 {
    background-image: url("./../../resources/images/background2.svg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding-bottom: 50px;

    .text {
      h3 {
        font-weight: 400;
        font-size: 30px;
        padding-left: 0px;
        padding-inline: 20px;
        padding-top: 0px;
      }
    }
    .carrousel {
      .slide {
        margin: 0;
        height: 150px;
        line-height: 150px;
        text-align: center;
        font-weight: 600;
        font-size: 30px;
      }
    }
  }

  .section4 {
    padding-bottom: 80px;
    text-align: center;

    .text {
      h3 {
        font-weight: 400;
        font-size: 30px;
        padding-top: 10px;
      }
      p {
        font-weight: 400;
        font-size: 20px;
        padding-inline: 40px;
      }
    }
    .buttons {
      width: 18%;
      height: 20%;
      margin-top: 20px;

      font-weight: 400;
      font-size: 30px;
      .icon {
        padding-right: 0px;
      }
    }
  }
}
