.ant-tabs{
  border: none !important;
.ant-tabs-nav:before {
    border-bottom: none !important;
  }
  .ant-tabs-nav:after {
    border-bottom: none !important;
  }
}
.ant-card-body {
  border-top: 0px solid #faf9f780; /* Example: Blue border */
}
.card-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}