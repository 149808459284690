.container {
  .contactDetails {
    padding-left: 60px;
    h3 {
      font-weight: 400;
      font-size: 60px;
    }
    p {
      font-size: 35px;
      text-align: left;
    }
    a {
      color: #f955c8;
      text-align: left;
      font-size: 35px;
    }
  }
  .imageContainer {
    width: 100%;
    padding-top: 100px;
    padding-right: 40px;
    text-align: center;
    .image {
      width: 150%;
      padding-right: 30px;
    }
  }
}

.mobile {
  .contactDetails {
    padding-inline: 40px;
    h3 {
      font-weight: 400;
      font-size: 30px;
      padding-top: 10px;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      padding-bottom: 10px;
    }
    a {
      text-align: left;
      font-size: 25px;
      color: #f955c8;
    }
  }
  .imageContainer {
    width: 100%;
    padding-top: 50px;
    text-align: center;
    .image {
      width: 100%;
      padding-right: 0px;
    }
  }
}
