.container {
  .heading {
    padding-left: 50px;
    h3 {
      font-weight: 400;
      font-size: 60px;
    }
    p {
      font-size: 35px;
      padding-bottom: 40px;
      text-align: left;
      font-style: italic;
    }
  }
  .description {
    padding-left: 50px;
    padding-top: 20px;
    padding-right: 20px;
    font-size: 20px;
  }
  .timeline {
    padding-left: 50px;
    padding-right: 20px;
    .icon {
      height: 30px;
    }
  }
}

.mobile {
  .heading {
    padding-inline: 40px;
    h3 {
      font-weight: 400;
      font-size: 30px;
      padding-top: 10px;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      padding-bottom: 0px;
    }
  }
  .description {
    padding-top: 0px;
    padding-inline: 50px;
    font-size: 18px;
    padding-bottom: 35px;
  }
  .timeline {
    padding-inline: 30px;
    .icon {
      height: 30px;
      color: "red";
    }
  }
}
